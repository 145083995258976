import removeSearchParam from '@client/src/utils/remove-search-param';
import updateSearchParams from '@client/src/utils/update-search-params';

const PARAM_KEY = 'tdc'; // tenantDisconnected
const PARAM_VALUE = 't'; // true

function isWindow(maybeWindow: any): maybeWindow is Window {
  return typeof maybeWindow !== 'undefined';
}

function rewriteParams(newHref: string, windowRef: Window) {
  windowRef.history.pushState({}, '', newHref);
}

function disconnectedTenantInterceptor({ res }: any) {
  const windowRef = window;
  if (!isWindow(windowRef)) {
    return;
  }
  const includesParamToSet = windowRef.location.search.includes(PARAM_VALUE);
  if (res.isTenantDisconnected && !includesParamToSet) {
    rewriteParams(
      updateSearchParams({ key: PARAM_KEY, value: PARAM_VALUE }),
      windowRef,
    );
    return;
  }
  if (includesParamToSet && !res.isTenantDisconnected) {
    rewriteParams(removeSearchParam(PARAM_KEY), windowRef);
    return;
  }
}

export default disconnectedTenantInterceptor;
