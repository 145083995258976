'use client';

import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import React from 'react';
import { Heading } from './heading';

interface ModalProps {
  triggerElement: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export function Modal({ triggerElement, title, children }: ModalProps) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{triggerElement}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-neutral-800 opacity-80" />
        <Dialog.Content className="bg-new-neutral-white fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-8 rounded-2xl">
          <Dialog.Title className="mb-10" asChild>
            <Heading level={2} size={40}>
              {title}
            </Heading>
          </Dialog.Title>
          {children}
          <Dialog.Close asChild>
            <button
              className="flex justify-center items-center p-0 bg-transparent border-none w-8 h-8 absolute top-8 right-8 cursor-pointer"
              aria-label="Close"
            >
              <Cross2Icon className="w-6 h-6" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
