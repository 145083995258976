import { MouseEventHandler, ReactElement } from 'react';

interface ButtonProps {
  children: React.ReactNode;
  type?: 'button' | 'submit';
  className?: string;
  isDisabled?: boolean;
  asLink?: boolean;
  filled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: ReactElement;
}

export function Button({
  children,
  type = 'button',
  className,
  isDisabled,
  onClick,
  filled = true,
  icon,
}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={isDisabled}
      className={[
        'flex items-center space-x-1 relative rounded-full px-8 py-4 cursor-pointer font-bold min-w-fit',
        filled
          ? 'bg-bluegenta text-new-neutral-white border-none'
          : 'bg-white border-gradient',
        filled
          ? 'text-[16px] leading-[0.9] text-gray-100 !font-[700]'
          : 'text-[16px] leading-[0.9] gradient-text !font-[700]',
        className,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {children}
      {icon && icon}
    </button>
  );
}
