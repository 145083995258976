function updateSearchParams(
  { key, value }: { key: string; value: string },
  currentUrl: string = window.location.href,
): string {
  const url = new URL(currentUrl);
  const searchParams = url.searchParams;
  searchParams.set(key, value);
  url.search = searchParams.toString();
  return url.href;
}

export default updateSearchParams;
