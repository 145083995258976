import { forwardRef } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Button } from './button';

interface ActionInputProps {
  inputType: React.HTMLInputTypeAttribute;
  placeholder: string;
  actionLabel: string | React.ReactNode;
  onSubmit: SubmitHandler<any>;

  actionClassName?: string;
  className?: string;
  isDisabled?: boolean;
  error?: string;
}

export const ActionInput = forwardRef<HTMLInputElement, ActionInputProps>(
  function ActionInput(
    {
      inputType,
      placeholder,
      actionLabel,
      onSubmit,

      actionClassName,
      className,
      isDisabled,
      error,
      ...restOfProps
    },
    ref,
  ) {
    const hasError = typeof error === 'string' && error.length > 1;

    return (
      <form noValidate onSubmit={onSubmit}>
        <div className="flex flex-col gap-2">
          <div
            className={[
              'flex p-1 border-solid rounded-full overflow-hidden bg-new-neutral-white',
              className,
              hasError
                ? 'border-red-200 border-[2px]'
                : 'border-new-text-100 border-[1px]',
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <input
              ref={ref}
              type={inputType}
              placeholder={placeholder}
              disabled={isDisabled}
              className="border-none p-5 outline-none !text-[20px] !leading-none w-full bg-transparent autofill:bg-new-neutral-white"
              {...restOfProps}
            />
            <Button
              type="submit"
              className={actionClassName}
              isDisabled={isDisabled || hasError}
            >
              {actionLabel}
            </Button>
          </div>
          {error ? (
            <span className="text-red-600 text-left px-6">{error}</span>
          ) : null}
        </div>
      </form>
    );
  },
);
