import * as Sentry from '@sentry/nextjs';

export default async function fetcher(
  accessToken: string | undefined,
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
) {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
  };
  return fetch(
    input,
    init
      ? {
          ...init,
          credentials: 'include',
          headers: {
            ...(init?.headers || {}),
            ...defaultHeaders,
          },
        }
      : { headers: defaultHeaders },
  )
    .then((response) => response.json())
    .catch((error) => {
      console.log('error when fetching', error);
      Sentry.captureException(error);
    });
}
