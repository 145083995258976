import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/public/assets/illustrations/illustration_dashboard.webp");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/public/logo/new-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/app/GoogleCaptchaWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/icons/design-update/Icon=circle-of-stars.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/icons/design-update/Icon=eye.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/icons/design-update/Icon=how-it-works-step1.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/icons/design-update/Icon=monitor.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/icons/design-update/Icon=report.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/icons/design-update/Icon=third-party.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/icons/design-update/Icon=warning.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/illustrations/design-update/office-icons-set.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/illustrations/design-update/product-screenshot.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/illustrations/design-update/reclaim-your-time-path.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/1.Security/1.Security/apps/client/src/assets/illustrations/design-update/transform-background.png");
;
import(/* webpackMode: "eager", webpackExports: ["EmailCTA"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/components/new-design/email-cta.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/components/new-design/modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/1.Security/1.Security/apps/client/src/layouts/_common/language-popover.tsx");
