function removeSearchParam(
  paramToRemove: string,
  currentUrl: string = window.location.href,
): string {
  const url = new URL(currentUrl);
  url.searchParams.delete(paramToRemove);
  return url.toString();
}

export default removeSearchParam;
