'use client';
import React from 'react';
import GoogleReCaptchaProvider from './GoogleReCaptchaProvider';

export default function GoogleCaptchaWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const recaptchaKey: string | undefined =
    process?.env?.NEXT_PUBLIC_RECAPTCHA_KEY;

  return (
    <GoogleReCaptchaProvider
      scriptProps={{
        async: true,
        defer: true,
      }}
      reCaptchaKey={recaptchaKey ?? 'NOT DEFINED'}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
}
