const sizes = [80, 64, 40, 24, 20, 18] as const;

interface HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
  className?: string;
  size?: (typeof sizes)[number];
  mobileSize?: number;
}

export function Heading({
  level,
  children,
  className,
  size,
  mobileSize,
}: HeadingProps) {
  const TagName = `h${level}` as keyof JSX.IntrinsicElements;
  const currentSize = size ?? sizes[level - 1];

  return (
    <TagName
      style={{
        '--Heading-currentSize': `${currentSize}px`,
        '--Heading-mobile-currentSize': `${mobileSize ?? currentSize * 0.8}px`,
      }}
      className={[
        `text-new-text-1000 !leading-none !font-[700] md:!text-[length:var(--Heading-currentSize)] !text-[length:var(--Heading-mobile-currentSize)]`,
        className,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {children}
    </TagName>
  );
}
